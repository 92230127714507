export default {
  methods: {
    getPlans(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getPlan(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans/${id}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createPlan(data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (
            data[key] &&
            data[key] != null &&
            key != "fromDate" &&
            key != "toDate"
          ) {
            if (key == "prices") {
              data[key].forEach((price, i) => {
                if (price.active && price.active === true) {
                  formData.append(`prices[${i}][name]`, price.name);
                  formData.append(`prices[${i}][description]`, price.name);
                  formData.append(`prices[${i}][duration]`, price.duration);
                  formData.append(`prices[${i}][tariff]`, price.tariff);
                  formData.append(`prices[${i}][active_from]`, data.fromDate);
                  formData.append(`prices[${i}][active_to]`, data.toDate);
                }
              });
            } else {
              formData.append(key, data[key]);
            }
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updatePlan(data, planId) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("_method", "PUT");
        Object.keys(data).forEach((key) => {
          if (
            data[key] &&
            data[key] != null &&
            key != "fromDate" &&
            key != "toDate"
          ) {
            if (key == "prices") {
              data[key].forEach((price, i) => {
                if (price.active && price.active === true) {
                  formData.append(`prices[${i}][name]`, price.name);
                  formData.append(`prices[${i}][description]`, price.name);
                  formData.append(`prices[${i}][duration]`, price.duration);
                  formData.append(`prices[${i}][tariff]`, price.tariff);
                  formData.append(`prices[${i}][active_from]`, data.fromDate);
                  formData.append(`prices[${i}][active_to]`, data.toDate);
                }
              });
            } else {
              formData.append(key, data[key]);
            }
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans/${planId}`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    setPlanStatus(status, id) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("status", status);
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans/${id}/change-status`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addPlanFeatures(features, planId) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("sync", 1); //For replacing all current features inside the plan
        features.forEach((feature, i) => {
          formData.append(`features[${i}][_id]`, feature._id);
          if (
            feature.selected_field_values &&
            feature.selected_field_values != null &&
            feature.selected_field_values.length > 0
          ) {
            feature.selected_field_values.forEach((val, v_i) => {
              formData.append(
                `features[${i}][meta][selected_field_values][${v_i}]`,
                val
              );
            });
          }
          if (feature.inputValue && feature.inputValue != null) {
            if (feature.unit == "credit") {
              formData.append(`features[${i}][quantity]`, 1);
              formData.append(`features[${i}][tariff]`, feature.inputValue);
            } else {
              formData.append(
                `features[${i}][quantity]`,
                parseInt(feature.inputValue)
              );
            }
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans/${planId}/features/add-multiple`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addPlanPrices(prices, planId) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("sync", 1); //For replacing all current prices inside the plan
        prices.forEach((price, i) => {
          formData.append(`prices[${i}][name]`, price.name);
          formData.append(`prices[${i}][description]`, price.description);
          formData.append(`prices[${i}][duration]`, price.duration);
          formData.append(`prices[${i}][tariff]`, price.tariff);
          // formData.append(
          //   `prices[${i}][allowed_total_credit]`,
          //   price.allowed_total_credit
          // );
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans/${planId}/prices/add-multiple`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },


    getPlanSubscribers(planId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/plans/${planId}/organizations`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },



  },
};
